import Image, { type StaticImageData } from 'next/image';
import { useWindowSize } from 'usehooks-ts';
import BannerLycraTule from '#/assets/images/BannerLycraTule.jpeg';
import BannerStella from '#/assets/images/BannerStella.jpeg';
import BannerTuleGlitter from '#/assets/images/BannerTuleGlitter.jpeg';
import BannerTweed from '#/assets/images/BannerTweed.jpeg';
import JornadaDeCompras from '#/assets/images/JornadaCompraSite.png';
import JornadaDeComprasMobile from '#/assets/images/JornadaCompraSite_mobile.png';
import { Icon } from '#/common/components/Icon';
import { AspectRatio } from '#/common/components/ui/aspect-ratio';
import { Button } from '#/common/components/ui/button';
import { useHeroCarousel } from '../hooks/useHeroCarousel';

interface Banner {
  alt: string;
  src: StaticImageData;
  src_mobile?: StaticImageData;
  href?: string;
}

const banners: Banner[] = [
  {
    alt: 'Lycra Tule Estampada',
    src: JornadaDeCompras,
    src_mobile: JornadaDeComprasMobile,
  },
  {
    alt: 'Lycra Tule Estampada',
    src: BannerLycraTule,
    href: '/catalogo/vestuario/moda-primavera-verao/lycra-tule',
  },
  {
    alt: 'Alfaiataria Stella',
    src: BannerStella,
    href: '/catalogo/vestuario/moda-outono-inverno/alfaiataria-moda-outono-inverno',
  },
  {
    alt: 'Tule Glitter Pesado',
    src: BannerTuleGlitter,
    href: '/catalogo/vestuario/festas-vestuario/tules',
  },
  {
    alt: 'Tweed Clássico e Atemporal',
    src: BannerTweed,
    href: '/catalogo/vestuario/moda-outono-inverno/la-e-tweed',
  },
];

function BannerImage({
  image,
  isMobile,
  priority,
}: { image: Banner; isMobile: boolean; priority: boolean }) {
  return (
    <AspectRatio ratio={isMobile ? 1.65 : 1.78}>
      <Image
        width={isMobile ? 860 : 1920}
        className="h-[260px] md:h-[360px] lg:h-[600px]"
        height={isMobile ? 520 : 1080}
        priority={priority}
        {...image}
        src={isMobile && image.src_mobile ? image.src_mobile : image.src}
      />
    </AspectRatio>
  );
}

export function HeroCarousel() {
  const { emblaRef, scrollPrev, scrollNext } = useHeroCarousel();
  const { width } = useWindowSize();

  return (
    <section className="relative h-[260px] w-full overflow-hidden md:h-[360px] lg:h-[600px]">
      <div ref={emblaRef}>
        <div className="flex">
          {banners.map((image, index) => {
            const isFirstSlide = index === 0;
            const isMobile = width < 640;

            if (image.href) {
              return (
                <a
                  key={`${index}-${image.alt}`}
                  href={image.href}
                  className="min-w-0 flex-[0_0_100%]"
                >
                  <BannerImage
                    image={image}
                    isMobile={isMobile}
                    priority={isFirstSlide}
                  />
                </a>
              );
            }
            return (
              <div
                key={`${index}-${image.alt}`}
                className="min-w-0 flex-[0_0_100%]"
              >
                <BannerImage
                  image={image}
                  isMobile={isMobile}
                  priority={isFirstSlide}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Button
        className="-translate-y-1/2 absolute top-1/2 left-2 hidden h-8 w-8 rounded-full md:flex"
        onClick={scrollPrev}
        variant="outline"
        size="icon"
      >
        <Icon
          name="arrow-left"
          className="h-4 w-4"
        />
        <span className="sr-only">Slide Anterior</span>
      </Button>
      <Button
        className="-translate-y-1/2 absolute top-1/2 right-2 hidden h-8 w-8 rounded-full md:flex"
        onClick={scrollNext}
        variant="outline"
        size="icon"
      >
        <Icon
          name="arrow-right"
          className="h-4 w-4"
        />
        <span className="sr-only">Slide Seguinte</span>
      </Button>
    </section>
  );
}
